import React from 'react';


const Footer = () => {
    return(
    <>
    <div class="container">
        <div class="text-right">
            <p>Copyright © Derechos Reservados. Diseñado Por... <a href='/'>MP Service S.A de C.V</a></p>
        </div>
    </div>
    </>    
    );
}

export default Footer;