import React from "react";
import "./../style/cards.css";
import "./../style/infiniteSlider.css";
import Reliable from "./../images/reliablec.avif";
import Lennox from "./../images/lenox.avif";
import Belimo from "./../images/belimo.avif";
import York from "../images/york.avif";
import Rib from "./../images/rib.avif";
import Adelte from "./../images/adelte.avif";

const Brands = () => {
  return (
    <div className="brands-container">
      <h1 className="text-center">MARCAS</h1>
      <div className="slider">
        <div className="slide-track">
          <div className="slide">
            <div className="image-container">
              <img src={Reliable} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.reliablecontrols.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reliable Controls
                  </a>{" "}
                  Es una Corporación dedicada a la venta de productos y servicios
                  en la industria de automatización de edificios. Fundada en 1986
                  en Surrey, Columbia Británica, Canadá. Actualmente la sede
                  corporativa se encuentra en Victoria, BC con soporte de ventas
                  en ubicaciones en todo el mundo.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Lennox} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a href="https://www.lennox.com/" target="_blank" rel="noreferrer">
                    Lennox International Inc.
                  </a>{" "}
                  Es un proveedor de productos de control climático para los
                  mercados de calefacción, ventilación, aire acondicionado y
                  refrigeración. fundada en 1895, en Marshalltown, Iowa, por Dave
                  Lennox, el cual desarrollo numerosos avances en las soluciones
                  de calefacción, refrigeración y control climático.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Belimo} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.belimo.com/us/en_US/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Belimo
                  </a>{" "}
                  Es líder mundial en el mercado en el desarrollo, producción y
                  comercialización de soluciones de actuadores para controlar
                  sistemas de calefacción, ventilación y aire acondicionado. Ofrece
                  innovaciones en comodidad, eficiencia energética y soluciones de
                  seguridad.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={York} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  York es el nombre de una compañía que comenzó en York,
                  Pennsylvania, EE. UU., En 1874, que desarrolló la marca York de
                  equipos de refrigeración y HVAC. La marca York ahora es
                  propiedad (desde 2005) de{" "}
                  <a
                    href="http://www.johnsoncontrols.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Johnson Controls
                  </a>{" "}
                  una compañía americana que ofrece productos y servicios para
                  optimizar la eficiencia energética en edificios.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Rib} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.functionaldevices.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Functional Devices, Inc
                  </a>{" "}
                  ha estado diseñando y fabricando dispositivos electrónicos de
                  calidad en los Estados Unidos de América desde 1969. Se ha
                  establecido como líder en HVAC, controles de edificios, gestión
                  de energía, ahorro de energía, controles de iluminación e
                  industrias inalámbricas.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Adelte} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.adelte.com/es/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Adelte
                  </a>{" "}
                  es una empresa con más de cinco décadas de experiencia en el
                  diseño, la fabricación, el suministro y el mantenimiento de
                  soluciones innovadoras y servicios orientados a los resultados
                  que mejoran las operaciones aeroportuarias, portuarias y
                  terrestre en todo el mundo.
                </p>
              </div>
            </div>
          </div><div className="slide">
            <div className="image-container">
              <img src={Reliable} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.reliablecontrols.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reliable Controls
                  </a>{" "}
                  Es una Corporación dedicada a la venta de productos y servicios
                  en la industria de automatización de edificios. Fundada en 1986
                  en Surrey, Columbia Británica, Canadá. Actualmente la sede
                  corporativa se encuentra en Victoria, BC con soporte de ventas
                  en ubicaciones en todo el mundo.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Lennox} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a href="https://www.lennox.com/" target="_blank" rel="noreferrer">
                    Lennox International Inc.
                  </a>{" "}
                  Es un proveedor de productos de control climático para los
                  mercados de calefacción, ventilación, aire acondicionado y
                  refrigeración. fundada en 1895, en Marshalltown, Iowa, por Dave
                  Lennox, el cual desarrollo numerosos avances en las soluciones
                  de calefacción, refrigeración y control climático.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Belimo} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.belimo.com/us/en_US/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Belimo
                  </a>{" "}
                  Es líder mundial en el mercado en el desarrollo, producción y
                  comercialización de soluciones de actuadores para controlar
                  sistemas de calefacción, ventilación y aire acondicionado. Ofrece
                  innovaciones en comodidad, eficiencia energética y soluciones de
                  seguridad.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={York} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  York es el nombre de una compañía que comenzó en York,
                  Pennsylvania, EE. UU., En 1874, que desarrolló la marca York de
                  equipos de refrigeración y HVAC. La marca York ahora es
                  propiedad (desde 2005) de{" "}
                  <a
                    href="http://www.johnsoncontrols.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Johnson Controls
                  </a>{" "}
                  una compañía americana que ofrece productos y servicios para
                  optimizar la eficiencia energética en edificios.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Rib} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.functionaldevices.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Functional Devices, Inc
                  </a>{" "}
                  ha estado diseñando y fabricando dispositivos electrónicos de
                  calidad en los Estados Unidos de América desde 1969. Se ha
                  establecido como líder en HVAC, controles de edificios, gestión
                  de energía, ahorro de energía, controles de iluminación e
                  industrias inalámbricas.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Adelte} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.adelte.com/es/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Adelte
                  </a>{" "}
                  es una empresa con más de cinco décadas de experiencia en el
                  diseño, la fabricación, el suministro y el mantenimiento de
                  soluciones innovadoras y servicios orientados a los resultados
                  que mejoran las operaciones aeroportuarias, portuarias y
                  terrestre en todo el mundo.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Reliable} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.reliablecontrols.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reliable Controls
                  </a>{" "}
                  Es una Corporación dedicada a la venta de productos y servicios
                  en la industria de automatización de edificios. Fundada en 1986
                  en Surrey, Columbia Británica, Canadá. Actualmente la sede
                  corporativa se encuentra en Victoria, BC con soporte de ventas
                  en ubicaciones en todo el mundo.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Lennox} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a href="https://www.lennox.com/" target="_blank" rel="noreferrer">
                    Lennox International Inc.
                  </a>{" "}
                  Es un proveedor de productos de control climático para los
                  mercados de calefacción, ventilación, aire acondicionado y
                  refrigeración. fundada en 1895, en Marshalltown, Iowa, por Dave
                  Lennox, el cual desarrollo numerosos avances en las soluciones
                  de calefacción, refrigeración y control climático.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Belimo} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.belimo.com/us/en_US/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Belimo
                  </a>{" "}
                  Es líder mundial en el mercado en el desarrollo, producción y
                  comercialización de soluciones de actuadores para controlar
                  sistemas de calefacción, ventilación y aire acondicionado. Ofrece
                  innovaciones en comodidad, eficiencia energética y soluciones de
                  seguridad.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={York} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  York es el nombre de una compañía que comenzó en York,
                  Pennsylvania, EE. UU., En 1874, que desarrolló la marca York de
                  equipos de refrigeración y HVAC. La marca York ahora es
                  propiedad (desde 2005) de{" "}
                  <a
                    href="http://www.johnsoncontrols.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Johnson Controls
                  </a>{" "}
                  una compañía americana que ofrece productos y servicios para
                  optimizar la eficiencia energética en edificios.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Rib} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.functionaldevices.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Functional Devices, Inc
                  </a>{" "}
                  ha estado diseñando y fabricando dispositivos electrónicos de
                  calidad en los Estados Unidos de América desde 1969. Se ha
                  establecido como líder en HVAC, controles de edificios, gestión
                  de energía, ahorro de energía, controles de iluminación e
                  industrias inalámbricas.
                </p>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="image-container">
              <img src={Adelte} alt="random" className="brandImage" />
              <div className="text-overlay">
                <p>
                  <a
                    href="https://www.adelte.com/es/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Adelte
                  </a>{" "}
                  es una empresa con más de cinco décadas de experiencia en el
                  diseño, la fabricación, el suministro y el mantenimiento de
                  soluciones innovadoras y servicios orientados a los resultados
                  que mejoran las operaciones aeroportuarias, portuarias y
                  terrestre en todo el mundo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
