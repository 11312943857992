import React from "react";

const autoControl = () => {
    return(
       <>
            <div class="text-center"> 
                <h1>Control y automatización</h1>
            </div>
        </> 
    );
}

export default autoControl;